// useFrameworkData.js
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGetDataFileByNameQuery } from '../slices/dataFilesApiSlice.js';
import { setFrameworkData } from '../slices/dataSlice.js';

const useFrameworkData = (framework) => {
  const dispatch = useDispatch();
  const frameworkData = useSelector((state) => state.data.frameworkData);

  // Remove the 'skip' option to always fetch data
  const { data, isLoading, error, refetch } = useGetDataFileByNameQuery(framework);

  useEffect(() => {
    if (data && !isLoading && !error) {
      dispatch(setFrameworkData(data));
    }
  }, [data, isLoading, error, dispatch]);

  return { frameworkData, isLoading, error, refetchFrameworkData: refetch };
};

export default useFrameworkData;

// src/components/FrameworkList.js
import React, { useEffect, useState } from 'react';
import {
  TbAlertCircle,
  TbCaretDownFilled,
  TbCaretRightFilled,
  TbFilePlus,
  TbSearch,
  TbX,
  TbEdit, // Import the rename icon
} from 'react-icons/tb';
import '../styles/components/FrameworkList.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setFramework, setFrameworksList } from '../slices/dataSlice';
import { useGetFrameworkGroupsQuery, useUpdateFrameworkGroupMutation } from '../slices/frameworkGroupAPISlice'; // Updated import
import NewMappingModal from '../modals/NewMappingModal';
import { processFile } from '../utils/fileUtils';
import { useModal } from '../context/ModalContext';
import EditGroupNameModal from '../modals/EditGroupNameModal';

const FrameworkList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showModal } = useModal(); // Updated to include hideModal

  // Extract frameworkName from URL parameters
  const { frameworkName } = useParams();
  const decodedFrameworkName = decodeURIComponent(frameworkName || '');

  const { data: frameworkGroupsData, isLoading, error } = useGetFrameworkGroupsQuery();
  const chosenFramework = useSelector((state) => state.data.framework);

  const [searchQuery, setSearchQuery] = useState('');
  const [expandedGroups, setExpandedGroups] = useState({});
  const [filteredFrameworks, setFilteredFrameworks] = useState({});

  const handleFrameworkClick = (frameworkName, groupName) => {
    navigate(`/${encodeURIComponent(frameworkName)}`);
    dispatch(setFramework(frameworkName));

    setExpandedGroups((prev) => ({
      ...prev,
      [groupName]: true,
    }));
  };

  useEffect(() => {
    if (frameworkGroupsData) {
      // Sort framework groups by name
      const sortedFrameworkGroups = [...frameworkGroupsData].sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      // Sort frameworks within each group alphabetically
      const groupedFrameworks = sortedFrameworkGroups.map((group) => ({
        id: group._id, // Include the group's ID
        group: group.name,
        frameworks: [...group.frameworks].sort((a, b) => a.localeCompare(b)),
      }));

      // Collect all frameworks
      const allFrameworks = groupedFrameworks.reduce((acc, group) => {
        return acc.concat(group.frameworks);
      }, []);

      const frameworksData = {
        allFrameworks,
        groupedFrameworks,
      };

      dispatch(setFrameworksList(frameworksData));
      setFilteredFrameworks(frameworksData);

      // Expand group if framework is selected via URL
      if (decodedFrameworkName) {
        let parentGroup = null;
        groupedFrameworks.forEach((group) => {
          if (group.frameworks.includes(decodedFrameworkName)) {
            parentGroup = group.group;
          }
        });

        if (parentGroup) {
          setExpandedGroups((prev) => ({
            ...prev,
            [parentGroup]: true,
          }));
        }
      }
    }
  }, [frameworkGroupsData, dispatch, decodedFrameworkName]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (evt) => {
        const result = processFile(evt, file);
        showModal(<NewMappingModal file={result} />);
      };
      reader.readAsArrayBuffer(file);
      e.target.value = null;
    }
  };

  const openFilePicker = () => {
    const dataFileInput = document.getElementById('dataFileInput');
    dataFileInput.click();
  };

  const frameworkList = useSelector((state) => state.data.frameworksList);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query === '') {
      setExpandedGroups({});
      setFilteredFrameworks(frameworkList);
      return;
    }

    const filteredGroupedFrameworks = frameworkList.groupedFrameworks
      .map((group) => {
        const groupNameMatches = group.group
          .toLowerCase()
          .includes(query.toLowerCase());

        if (groupNameMatches) {
          // Include entire group if group name matches
          return group;
        } else {
          // Filter frameworks within the group
          const filteredFrameworks = group.frameworks.filter((framework) =>
            framework.toLowerCase().includes(query.toLowerCase())
          );
          if (filteredFrameworks.length > 0) {
            return { ...group, frameworks: filteredFrameworks };
          }
          return null;
        }
      })
      .filter((group) => group !== null);

    // Expand groups that have matching frameworks or group names
    const newExpandedGroups = {};
    filteredGroupedFrameworks.forEach((group) => {
      newExpandedGroups[group.group] = true;
    });

    setExpandedGroups(newExpandedGroups);
    setFilteredFrameworks({
      groupedFrameworks: filteredGroupedFrameworks,
    });
  };

  const toggleGroup = (groupName) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [groupName]: !prev[groupName],
    }));
  };

  const handleRenameGroup = (groupId, currentName) => {
    showModal(<EditGroupNameModal groupName={currentName} id={groupId}/>);
  };

  const { groupedFrameworks = [] } = filteredFrameworks;

  return (
    <div className="framework-list">
      <div className="framework-list-header">
        <h3>Frameworks</h3>
        <button className="icon-button" onClick={openFilePicker}>
          <TbFilePlus className="plus-icon" size={26} />
        </button>
        <input
          id="dataFileInput"
          type="file"
          accept=".xlsx, .xls"
          style={{ display: 'none' }}
          onChange={(e) => handleFileChange(e, 'framework')}
        />
      </div>

      {/* Search Bar */}
      <div className="search-bar">
        <TbSearch className="search-icon" size={16} />
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchChange}
        />
        {searchQuery && (
          <TbX
            className="clear-icon"
            onClick={() => {
              setSearchQuery('');
              setExpandedGroups({});
              setFilteredFrameworks(frameworkList);
            }}
            size={16}
            role="button"
            tabIndex={0}
            aria-label="Clear search"
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                setSearchQuery('');
                setExpandedGroups({});
                setFilteredFrameworks(frameworkList);
              }
            }}
          />
        )}
      </div>

      {isLoading ? (
        <div className="loading-frameworks">
          <p>Loading frameworks...</p>
        </div>
      ) : error ? (
        <div className="error-message">
          <TbAlertCircle size={20} color="#dc3545" />
          <span>{error?.data?.message || error.error}</span>
        </div>
      ) : (
        <div className="framework-list-body">
          {groupedFrameworks.map((group) => (
            <div key={group.group} className="framework-group">
              <div
                className={`group-header ${
                  expandedGroups[group.group] ? 'active' : ''
                }`}
                aria-expanded={expandedGroups[group.group] ? 'true' : 'false'}
              >
                <div
                  className="group-header-right"
                  onClick={() => toggleGroup(group.group)}
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      toggleGroup(group.group);
                    }
                  }}
                >
                  <span className="group-toggle">
                    {expandedGroups[group.group] ? (
                      <TbCaretDownFilled />
                    ) : (
                      <TbCaretRightFilled />
                    )}
                  </span>
                  <span className="group-name">{group.group}</span>
                </div>
                {/* <button
                  className="group-action-button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleRenameGroup(group.id, group.group);
                  }}
                >
                  <TbEdit size={16} />
                </button> */}
              </div>
              {expandedGroups[group.group] && (
                <ul>
                  {group.frameworks.map((framework) => (
                    <li key={framework}>
                      <button
                        className={`framework-button ${
                          chosenFramework === framework ? 'active' : ''
                        }`}
                        onClick={() => handleFrameworkClick(framework, group.group)}
                      >
                        {framework}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FrameworkList;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  theme: localStorage.getItem('theme') || 'light',
  isTaskMatchCollapsed: true,
  isNotificationCollapsed: true,
  isNotificationShown: false,
  isNotificationCenterFirstRender: true,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setTaskMatchCollapsed: (state, action) => {
      state.isTaskMatchCollapsed = action.payload;
    },
    setNotificationCollapsed: (state, action) => {
      state.isNotificationCollapsed = action.payload;
    },
    setNotificationShown: (state, action) => {
      state.isNotificationShown = action.payload;
    },
    setIsNotificationCenterFirstRender: (state, action) => {
      state.isNotificationCenterFirstRender = action.payload;
    },
  },
});

export const {
  setIsLoading,
  setTheme,
  setTaskMatchCollapsed,
  setNotificationCollapsed,
  setNotificationShown,
  setIsNotificationCenterFirstRender,
} = uiSlice.actions;

export default uiSlice.reducer;

// src/modals/CynomiFileModal.js
import React, { useState } from 'react';
import Dropdown from '../components/Dropdown';
import DataTable from '../components/DataTable';
import { toast } from 'react-toastify';
import { setNotificationCollapsed, setNotificationShown } from '../slices/uiSlice';
import { useDispatch } from 'react-redux';
import Modal from '../components/Modal';
import { useModal } from '../context/ModalContext';

const CynomiFileModal = ({ file, handleFileUpload }) => {
  const { hideModal } = useModal();
  const dispatch = useDispatch();
  const [taskIdHeader, setTaskIdHeader] = useState('task.id');
  const [taskNameHeader, setTaskNameHeader] = useState('task.name');
  const [taskDescriptionHeader, setTaskDescriptionHeader] = useState('task.description');
  const [policyNameHeader, setPolicyNameHeader] = useState('policy.title');

  const handleFormSubmit = async () => {
    if (!taskIdHeader || !taskNameHeader || !taskDescriptionHeader || !policyNameHeader) {
      toast.warn('Please select all required headers');
      return;
    }

    const mapping = {
      taskIdHeader,
      taskNameHeader,
      taskDescriptionHeader,
      policyNameHeader,
    };
    hideModal();
    dispatch(setNotificationCollapsed(false));
    dispatch(setNotificationShown(true));

    await handleFileUpload(file, mapping);

    dispatch(setNotificationCollapsed(true));
    setTimeout(() => {
      dispatch(setNotificationShown(false));
    }, 500);
  };

  return (
    <Modal title={`Update Cynomi Tasks: "${file ? file.name : ''}"`}>
      {file && file.data && file.data.length > 0 ? (
        <>
          <label className="table-label">File: "{file.name}"</label>
          <div className="data-table-container">
            <DataTable data={file.data} handleRowClick={() => {}} />
          </div>
          <div className="form-container">
            <div className="form-grid">
            <div className="select-cell">
            <div className="select-wrapper">
              <label>The ID of the task is in:</label>
              <Dropdown
                up
                title={taskIdHeader || 'Choose Header...'}
                options={file.data[0]}
                multiple={false}
                value={[taskIdHeader]}
                onChange={([selected]) => setTaskIdHeader(selected)}
              />
            </div>
          </div>
          <div className="select-cell">
            <div className="select-wrapper">
              <label>The name of the task is in:</label>
              <Dropdown
                up
                title={taskNameHeader || 'Choose Header...'}
                options={file.data[0]}
                multiple={false}
                value={[taskNameHeader]}
                onChange={([selected]) => setTaskNameHeader(selected)}
              />
            </div>
          </div>
          <div className="select-cell">
            <div className="select-wrapper">
              <label>The description of the task is in:</label>
              <Dropdown
                up
                title={taskDescriptionHeader || 'Choose Header...'}
                options={file.data[0]}
                multiple={false}
                value={[taskDescriptionHeader]}
                onChange={([selected]) => setTaskDescriptionHeader(selected)}
              />
            </div>
          </div>
          <div className="select-cell">
            <div className="select-wrapper">
              <label>The name of the policy is in:</label>
              <Dropdown
                up
                title={policyNameHeader || 'Choose Header...'}
                options={file.data[0]}
                multiple={false}
                value={[policyNameHeader]}
                onChange={([selected]) => setPolicyNameHeader(selected)}
              />
            </div>
          </div>
            </div>
          </div>
          <button className="form-action-button" onClick={handleFormSubmit}>
            SUBMIT
          </button>
        </>
      ) : (
        <p>Loading...</p> // Or any placeholder content
      )}
    </Modal>
  );
};

export default CynomiFileModal;

// src/modals/ReuploadModal.js
import React, { useEffect, useState } from 'react';
import Modal from '../components/Modal';
import { useModal } from '../context/ModalContext';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setFramework } from '../slices/dataSlice';
import { toast } from 'react-toastify';
import { useReuploadDataFileMutation } from '../slices/dataFilesApiSlice';
import { setNotificationShown, setNotificationCollapsed } from '../slices/uiSlice';
import DataTable from '../components/DataTable';
import Dropdown from '../components/Dropdown';

const ReuploadModal = ({ frameworkName, excelData }) => {
  const { hideModal } = useModal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [reuploadDataFile] = useReuploadDataFileMutation();
  const [newReuploadName, setNewReuploadName] = useState(`${frameworkName}_reupload`);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [colsToKeep, setColsToKeep] = useState([]);

  useEffect(() => {
    if (excelData) {
      const headers = excelData.data[0];
      const userMappingIndex = excelData.userMappingIndex;
      setColsToKeep([...headers.slice(0, userMappingIndex - 2), headers[userMappingIndex]]);
      setSelectedFilters(headers.slice(0, userMappingIndex - 2));
    }
  }, [excelData]);

  const handleReupload = async () => {
    try {
      hideModal();
      dispatch(setNotificationCollapsed(false));
      dispatch(setNotificationShown(true));

      const result = await reuploadDataFile({
        name: frameworkName,
        newName: newReuploadName,
        selectedFilters,
        colsToKeep,
      }).unwrap();

      if (result.error) {
        toast.error(result.error.data.message);
      } else {
        dispatch(setFramework(newReuploadName));
        navigate(`/${newReuploadName}`);
      }

      dispatch(setNotificationCollapsed(true));
      setTimeout(() => {
        dispatch(setNotificationShown(false));
      }, 500);
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
    hideModal();
  };

  return (
    <Modal title={`Reupload ${frameworkName}`}>
      {excelData && (
        <>
          <div className="data-table-container">
            <p className="input-label">Showing "{excelData.name}":</p>
            <DataTable data={excelData.data} handleRowClick={() => {}} />
          </div>
          <div className="input-container">
            <p className="input-label">Enter name for reuploaded file:</p>
            <input
              className="input-field"
              type="text"
              value={newReuploadName}
              onChange={(e) => setNewReuploadName(e.target.value)}
            />
          </div>
          <div className="form-grid">
            <div className="select-cell">
              <p className="input-label">Match Mapping By:</p>
              <Dropdown
                up
                title={
                  selectedFilters.length > 0
                    ? `${selectedFilters.length} Headers Chosen`
                    : 'Choose Headers...'
                }
                options={excelData.data[0]}
                multiple
                value={selectedFilters}
                onChange={setSelectedFilters}
              />
            </div>
            <div className="select-cell">
              <p className="input-label">Columns to keep:</p>
              <Dropdown
                up
                title={
                  colsToKeep.length > 0 ? `${colsToKeep.length} Headers Chosen` : 'Choose Headers...'
                }
                options={excelData.data[0]}
                multiple
                value={colsToKeep}
                onChange={setColsToKeep}
              />
            </div>
          </div>
          <div className="button-container">
            <button className="form-action-button" onClick={handleReupload}>
              Reupload
            </button>
            <button className="form-action-button" onClick={hideModal}>
              Cancel
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ReuploadModal;

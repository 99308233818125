import { BASE_URL, NOTIFICATIONS_URL } from '../constants.js'
import { apiSlice } from './apiSlice.js'

export const notificationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Create a new notification
    createNotification: builder.mutation({
      query: (data) => ({
        url: `${NOTIFICATIONS_URL}/create`,
        method: 'POST',
        credentials: 'include',
        body: data
      })
    }),

    // Update an existing notification
    updateNotification: builder.mutation({
      query: ({ id, data }) => ({
        url: `${NOTIFICATIONS_URL}/${id}`,
        method: 'PUT',
        credentials: 'include',
        body: data
      })
    }),

    // Fetch all notifications
    getNotifications: builder.query({
      query: () => ({
        url: `${NOTIFICATIONS_URL}`,
        method: 'GET',
        credentials: 'include'
      }),
      providesTags: ['Notification']
    }),

    // Subscribe to real-time notifications using SSE
    subscribeToNotifications: builder.query({
      queryFn: () => {
        return {
          // This will be handled manually by listening to SSE
          data: null
        };
      },
      async onCacheEntryAdded(arg, { cacheDataLoaded, cacheEntryRemoved, updateCachedData }) {
        try {
          const eventSource = new EventSource(`${BASE_URL}${NOTIFICATIONS_URL}/subscribe`);

          eventSource.onmessage = (event) => {
            const notification = JSON.parse(event.data);
            console.log('Received notification:', notification);

            updateCachedData((draft) => {
              // Ensure the draft is an array before pushing
              if (!draft) {
                draft = []; // Initialize draft as an empty array if undefined or null
              }
              draft.push(notification);
            });
          };

          await cacheDataLoaded;
          await cacheEntryRemoved;
          eventSource.close();
        } catch (err) {
          console.error('Failed to subscribe to notifications:', err);
        }
      }
    })
  })
})

export const {
  useCreateNotificationMutation,
  useUpdateNotificationMutation,
  useGetNotificationsQuery,
  useSubscribeToNotificationsQuery
} = notificationApiSlice;

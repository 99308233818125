import React from "react";
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
import "../styles/components/TaskRowCheckBox.css";

const TaskRowCheckBox = ({ID, checked, addToMappingHandler, disabled = false}) => {    
    return (
        <div className="checkbox-container">
            <button className="checkbox-button" onClick={e => {
                e.preventDefault();
                if (!disabled) {
                    e.stopPropagation();
                    addToMappingHandler({UID: ID});
                }
            }}>
                {checked ? (
                    <MdOutlineCheckBox
                        size={38}
                    />
                ) : (
                    <MdOutlineCheckBoxOutlineBlank
                        size={38}
                    />
                )}
            </button>
        </div>
    );
}

export default TaskRowCheckBox;
// ErrorMessage.js
import React from 'react';
import { TbFileOff } from 'react-icons/tb';
import '../styles/components/ErrorMessage.css';

const ErrorMessage = ({ message }) => (
  <div className="error-container">
    <TbFileOff size={80} color="var(--secondary-color)" />
    <h1>Oops! Something went wrong</h1>
    <p>{message}</p>
  </div>
);

export default ErrorMessage;

import React from 'react';
import './../styles/components/ProgressBar.css';

const ProgressBar = ({ progress }) => {
  return (
    <div className="progress-bar-container">
      <div className="progress-bar">
        <div
          className="progress-bar-progress"
          style={{ width: `${progress}%` }}
        >
          {/* The shimmer effect */}
          <div className="shimmer"></div>
        </div>
      </div>
      <span className="progress-bar-percentage">{progress}%</span>
    </div>
  );
};

export default ProgressBar;

export const calculateRows = (text, type) => {
  const maxRows = 7;
  const charPerLine = {
    'FULL': 100,
    'HALF': 50,
    'SMALL': 25,
  };
  const rows = Math.ceil(text.length / charPerLine[type]);
  return `${Math.min(rows, maxRows)}`;
};

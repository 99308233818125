// useCynomiData.js
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGetCynomiCsvQuery } from '../slices/dataFilesApiSlice.js';
import { setCynomiCsvData } from '../slices/dataSlice.js';

const useCynomiData = () => {
  const dispatch = useDispatch();
  const cynomiCsvData = useSelector((state) => state.data.cynomiCsvData);

  const { data, isLoading, error, refetch } = useGetCynomiCsvQuery({
    skip: !!cynomiCsvData?.length,
  });

  useEffect(() => {
    if (data && !isLoading && !error && (!cynomiCsvData || !cynomiCsvData.length)) {
      dispatch(setCynomiCsvData(data));
    } else if (!cynomiCsvData || !cynomiCsvData.length) {
      refetch();
    }
  }, [data, isLoading, error, dispatch, cynomiCsvData, refetch]);

  return { cynomiCsvData, isLoading, error };
};

export default useCynomiData;

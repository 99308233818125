import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import '../styles/components/FrameworkTable.css';
import DataTable from './DataTable';
import { HashLoader } from 'react-spinners';
import { TbCopy, TbSortAscending, TbSortDescending } from 'react-icons/tb';

const FrameworkTable = () => {
  const navigate = useNavigate();
  const framework = useSelector((state) => state.data.framework);
  const frameworkData = useSelector((state) => state.data.frameworkData);
  const [searchParams, setSearchParams] = useSearchParams();

  // Local state for filtered data and validation of statistics
  const [filteredData, setFilteredData] = useState([]);
  const [hasValidStatistics, setHasValidStatistics] = useState(false);

  // Extract parameters from URL
  const includedInAIFilter = searchParams.get('includedInAI') || '';
  const freeTextSearchTerm = searchParams.get('search') || '';
  const sortDirection = searchParams.get('sort') || 'asc';

  const handleRowClick = (rowData) => {
    const controlIndex = rowData[frameworkData.controlIndex];
    navigate(`/${framework}/${encodeURIComponent(controlIndex)}?${searchParams.toString()}`);
  };

  const handleStatisticsClick = (filter) => {
    const filterMap = {
      countAll: 'All',
      countPartial: 'Partial',
      countNone: 'None',
      totalRows: '',
    };
    setSearchParams({ includedInAI: filterMap[filter] || '' });
  };

  const handleSortChange = () => {
    const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSearchParams({ search: freeTextSearchTerm, includedInAI: includedInAIFilter, sort: newSortDirection });
  };

  const handleSearchChange = (e) => {
    setSearchParams({ search: e.target.value, includedInAI: includedInAIFilter, sort: sortDirection });
  };

  const handleAIFilterChange = (e) => {
    setSearchParams({ search: freeTextSearchTerm, includedInAI: e.target.value, sort: sortDirection });
  };

  useEffect(() => {
    if (frameworkData && frameworkData.data) {
      // Check if at least one statistic is not null
      if (frameworkData.statistics) {
        const hasValidStat = Object.values(frameworkData.statistics).some(
          (value) => value !== null
        );
        setHasValidStatistics(hasValidStat);
      }
  
      const handleCopyColumn = (col) => {
        if (frameworkData && frameworkData.data.length > 1) {
          const aiMatchUIDIndex = frameworkData.data[0].indexOf(col);
          if (aiMatchUIDIndex !== -1) {
            const columnData = frameworkData.data.map(row => row[aiMatchUIDIndex]).join('\n');
            navigator.clipboard.writeText(columnData)
              .then(() => toast.success(`${col} copied to clipboard`))
              .catch(() => toast.error('Copy failed'));
          }
        }
      };
  
      // Filter and sort data
      const headerRow = [
        ...frameworkData.data[0].map(itm => (
          <div className="header-button" tabIndex={0}>{itm}<button className="copy-button" onClick={() => handleCopyColumn(itm)}><TbCopy /></button></div>
        ))
      ];
  
      let tempData = [...frameworkData.data.slice(1)];
  
      if (sortDirection === 'desc') {
        tempData = tempData.reverse();
      }
  
      if (hasValidStatistics && includedInAIFilter) {
        tempData = tempData.filter((row) => row[row.length - 2]?.toString().toLowerCase().includes(includedInAIFilter.toLowerCase()));
      }
  
      if (freeTextSearchTerm) {
        tempData = tempData.filter((row) =>
          Object.values(row).some((val) =>
            val.toString().toLowerCase().includes(freeTextSearchTerm.toLowerCase())
          )
        );
      }
  
      setFilteredData([headerRow, ...tempData]);
    }
  }, [frameworkData, includedInAIFilter, freeTextSearchTerm, sortDirection, hasValidStatistics]);
  

  const formatLabel = (key) => {
    switch (key) {
      case 'totalRows':
        return 'Total Rows';
      case 'countAll':
        return "'All' Matches";
      case 'countPartial':
        return "'Partial' Matches";
      case 'countNone':
        return "'None' Matches";
      case 'matchedPredefinedCount':
        return 'Matched Predefined Tasks';
      case 'firstPredefinedMatchedCount':
        return 'First Predefined Matched';
      case 'fullTaskMatchingCount':
        return 'Full Task Matching';
      default:
        return key;
    }
  };

  const calculatePercentage = (count, totalRows) => {
    if (totalRows && count !== null) {
      return ((count / totalRows) * 100).toFixed(2);
    }
    return null;
  };

  return (
    <>
      {!framework || framework.length === 0 ? (
        <div className="no-file-found">
          <h1>Welcome to Cynomi AI Content Helper</h1>
          <p>Select a framework from the sidebar to get started</p>
        </div>
      ) : !frameworkData || !frameworkData.data ? (
        <HashLoader className="table-spinner" color="#8D8D8D" size={100} />
      ) : (
        <>
          <div className="table-filter">
            <input
              type="text"
              placeholder="Free Text Search..."
              value={freeTextSearchTerm}
              onChange={handleSearchChange}
              name="search"
            />
            {hasValidStatistics && (
              <>
                <input
                  type="text"
                  placeholder="Filter By Included in AI Match"
                  value={includedInAIFilter}
                  onChange={handleAIFilterChange}
                  list="included-options"
                  name="includedInAI"
                />
                <datalist id="included-options">
                  {['All', 'None', 'Partial']
                    .map((option, index) => (
                      <option key={index} value={option} />
                    ))}
                </datalist>
              </>
            )}
            <button onClick={handleSortChange}>
              Sort &nbsp;
              {sortDirection === 'asc' ? (
                <TbSortAscending size={24} />
              ) : (
                <TbSortDescending size={24} />
              )}
            </button>
          </div>
          <DataTable data={filteredData} handleRowClick={handleRowClick} filtersDisabled />
        </>
      )}

      {/* Render the statistics section below the table */}
      {hasValidStatistics && frameworkData?.statistics && framework?.length > 0 && (
        <div className="statistics-section">
          <div className="statistics-grid">
            {['totalRows', 'countAll', 'countPartial', 'countNone'].map((key) => (
              <div
                key={key}
                className="statistics-item"
                tabIndex={0}
                onClick={() => handleStatisticsClick(key)}
              >
                <strong>{formatLabel(key)}:</strong>
                {frameworkData.statistics[key]}
                {frameworkData.statistics.totalRows
                  ? ` (${calculatePercentage(
                      frameworkData.statistics[key],
                      frameworkData.statistics.totalRows
                    )}%)`
                  : ''}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default FrameworkTable;

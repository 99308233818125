import React from 'react'

const ScoreFilter = ({ scoreFilter, setScoreFilter, min=0, max=1, step=0.1 }) => {
    return (
        <div className="score-filter-container">
            <label htmlFor="scoreFilter" className="score-filter-label">Score Filter:</label>
            <input 
                type="number"
                id="scoreFilter"
                className="score-filter-input"
                value={scoreFilter}
                onChange={(e) => setScoreFilter(e.target.value)}
                min={min}
                max={max}
                step={step}
                style={{ borderRadius: '4px', border: '1px solid #ccc', padding: '3px' }}
            />
        </div>
    );
};

export default ScoreFilter
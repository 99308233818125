import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TbSortAscending, TbSortDescending } from 'react-icons/tb';
import DataTable from './DataTable';
import TaskRowCheckBox from './TaskRowCheckBox';

const AllCynomiDataTable = ({
  handleRowClick,
  addToMappingHandler,
  disabledCheckBox = false,
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [freeTextSearchTerm, setFreeTextSearchTerm] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const cynomiCsvData = useSelector((state) => state.data.cynomiCsvData);
  const mapping = useSelector((state) => state.data.mapping);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSortChange = () => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  useEffect(() => {
    if (cynomiCsvData) {
      setFilteredData(cynomiCsvData);
    }
  }, [cynomiCsvData]);

  useEffect(() => {
    if (cynomiCsvData) {
      let tempData = [...cynomiCsvData]; // Exclude headers

      // Handle Sorting
      if (sortDirection === 'desc') {
        tempData = tempData.reverse();
      }

      // Handle Policy-Based Filtering with Comma-Separated Values
      if (searchTerm) {
        const policyTerms = searchTerm
          .split(',')
          .map((term) => term.trim().toLowerCase());
        tempData = tempData.filter((row) =>
          policyTerms.some((term) =>
            row['Policy'].toString().toLowerCase().includes(term)
          )
        );
      }

      // Handle Free-Text Search with Comma-Separated Values
      if (freeTextSearchTerm) {
        const searchTerms = freeTextSearchTerm
          .split(',')
          .map((term) => term.trim().toLowerCase());
        tempData = tempData.filter((row) =>
          searchTerms.some((term) =>
            Object.values(row).some((val) =>
              val.toString().toLowerCase().includes(term)
            )
          )
        );
      }

      setFilteredData(tempData);
    }
  }, [searchTerm, sortDirection, cynomiCsvData, freeTextSearchTerm]);

  let cynomiallData = [
    ['Selected?', 'Task UID', 'Task', 'Task Description', 'Policy'],
  ];
  cynomiallData.push(
    ...filteredData.map((task) => [
      <TaskRowCheckBox
        ID={task.ID}
        checked={mapping.split(', ').includes(task.ID)}
        addToMappingHandler={addToMappingHandler}
        disabled={disabledCheckBox}
      />,
      task.ID,
      task.Task,
      task['Task Description'],
      task['Policy'],
    ])
  );

  return (
    <div className="cynomi-all-data-adding">
      <div className="table-header-wrapper">
        <div className="table-header">
          <p className="table-label">Choose tasks:</p>
          <div className="table-filter-wrapper">
            <div className="table-filter">
              <input
                type="text"
                placeholder="Enter Text..."
                value={freeTextSearchTerm}
                onChange={(e) => setFreeTextSearchTerm(e.target.value)}
                name="search"
              />
              <input
                type="text"
                placeholder="Policy Search..."
                value={searchTerm}
                onChange={handleSearchChange}
                list="policies"
                name="policySearch"
              />
              <datalist id="policies">
                {Array.from(
                  new Set(cynomiCsvData.map((task) => task['Policy']))
                )
                  .sort()
                  .map((policy, index) => (
                    <option key={index} value={policy} />
                  ))}
              </datalist>
              <button onClick={handleSortChange} className="custom-button">
                Sort &nbsp;
                {sortDirection === 'asc' ? (
                  <TbSortAscending size={24} />
                ) : (
                  <TbSortDescending size={24} />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="table-body-wrapper">
        <div className="table-body">
          <DataTable
            data={cynomiallData}
            handleRowClick={handleRowClick}
            filtersDisabled={true}
          />
        </div>
      </div>
    </div>
  );
};

export default AllCynomiDataTable;

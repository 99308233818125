// src/slices/frameworkSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  framework: null,
  frameworksList: [],
  mapping: '',
  frameworkData: {},
  cynomiCsvData: [],
};

const dataSlice = createSlice({
  name: 'framework',
  initialState,
  reducers: {
    setFramework: (state, action) => {
      state.framework = action.payload;
    },
    setFrameworksList: (state, action) => {
      state.frameworksList = action.payload;
    },
    setMapping: (state, action) => {
      state.mapping = action.payload;
    },
    setFrameworkData: (state, action) => {
      state.frameworkData = action.payload;
    },
    updateFrameworkData: (state, action) => {
      state.frameworkData = {
        ...state.frameworkData,
        ...action.payload,
      };
    },
    setCynomiCsvData: (state, action) => {
        state.cynomiCsvData = action.payload;
    },
  },
});

export const {
  setFramework,
  setFrameworksList,
  setMapping,
  setFrameworkData,
  updateFrameworkData,
  setCynomiCsvData,
} = dataSlice.actions;

export default dataSlice.reducer;

import React, { useState } from 'react';
import '../styles/components/DynamicTaskTable.css';

const AddTaskForm = ({ isEditMode, onSubmit }) => {
  const [formData, setFormData] = useState({ taskName: '' });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form className="add-task-form" onSubmit={handleSubmit}>
      <div className="form-group-row">
        <div className="form-group">
          <label htmlFor="taskName">Task Name</label>
          <input
            type="text"
            id="taskName"
            value={formData.taskName}
            onChange={handleChange}
            required
          />
        </div>
      </div>
      <div className="form-group-row">
        <button type="submit" className="submit-button" disabled={!isEditMode}>Submit</button>
      </div>
    </form>
  );
};

export default AddTaskForm;

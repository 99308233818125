// src/modals/ConfirmDeleteModal.js
import React from 'react';
import Modal from '../components/Modal';
import { useModal } from '../context/ModalContext';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setFramework } from '../slices/dataSlice';
import { toast } from 'react-toastify';
import { useDeleteDataFileMutation } from '../slices/dataFilesApiSlice';

const ConfirmDeleteModal = ({ frameworkName }) => {
  const { hideModal } = useModal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteDataFile] = useDeleteDataFileMutation();

  const handleConfirmedDelete = async () => {
    try {
      await deleteDataFile(frameworkName).unwrap();
      dispatch(setFramework(null));
      navigate('/');
      toast.success(`${frameworkName} framework deleted successfully`);
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
    hideModal();
  };

  return (
    <Modal title="Confirm Deletion">
      <p>
        Are you sure you want to delete <strong>{frameworkName}</strong> framework?
      </p>
      <div className="button-container">
        <button className="form-action-button" onClick={handleConfirmedDelete}>
          Yes
        </button>
        <button className="form-action-button" onClick={hideModal}>
          No
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;

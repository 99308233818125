// src/components/Modal.js
import React, { createContext, useEffect, useState, useCallback } from 'react';
import { useModal } from '../context/ModalContext';
import '../styles/global/modal.css';

export const ModalCloseContext = createContext();

const Modal = ({ title, children }) => {
  const { hideModal } = useModal();
  const [visible, setVisible] = useState(false);

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  useEffect(() => {
    setVisible(true);
    document.body.style.overflow = 'hidden'; // Disable scrolling when modal is open
    return () => {
      document.body.style.overflow = ''; // Re-enable scrolling when modal is closed
    };
  }, []);

  useEffect(() => {
    const closeOnEscape = (e) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    };
    window.addEventListener('keydown', closeOnEscape);
    return () => {
      window.removeEventListener('keydown', closeOnEscape);
    };
  }, [handleClose]);

  const overlayClass = 'modal-overlay';
  const contentClass = visible ? 'modal-content fade-in' : 'modal-content fade-out';

  const handleTransitionEnd = (e) => {
    // Check if the transition is on the modal content and opacity property
    if (e.target === e.currentTarget && e.propertyName === 'opacity' && !visible) {
      hideModal();
    }
  };

  return (
    <div className={overlayClass} onClick={handleClose}>
      <div
        className={contentClass}
        onClick={(e) => e.stopPropagation()}
        onTransitionEnd={handleTransitionEnd}
      >
        <div className="modal-header">
          {title && <h3>{title}</h3>}
          <button className="modal-close-button" onClick={handleClose}>
            &times;
          </button>
        </div>
        <ModalCloseContext.Provider value={handleClose}>
          <div className="modal-content-body">{children}</div>
        </ModalCloseContext.Provider>
      </div>
    </div>
  );
};

export default Modal;

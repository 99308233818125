import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './slices/apiSlice.js';
import authReducer from './slices/authSlice.js';
import uiSlice from './slices/uiSlice.js';
import messageSlice from './slices/messageSlice.js';
import dataSlice from './slices/dataSlice.js';

// Create store
const store = configureStore({
  
  reducer: {
    auth: authReducer,
    ui: uiSlice,
    message: messageSlice,
    data: dataSlice,
    api: apiSlice.reducer
  },
  middleware: (getDefaultMiddleware) => (getDefaultMiddleware().concat(apiSlice.middleware)),
  devTools: true
});

export default store;

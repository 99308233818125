import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedMessage: null,
  showMessageModal: false,
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setSelectedMessage: (state, action) => {
      state.selectedMessage = action.payload;
    },
    setShowMessageModal: (state, action) => {
      state.showMessageModal = action.payload;
    },
  },
});

export const {
  setSelectedMessage,
  setShowMessageModal,
} = messageSlice.actions;

export default messageSlice.reducer;

// src/pages/TaskPage.js
import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { HashLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import Navbar from '../components/Navbar';
import TasksList from '../components/TasksList';
import { TbEdit, TbDeviceFloppy as TbSave, TbX } from 'react-icons/tb';
import {
  useUpdateDataFileMutation,
  useUpdateDataFileStatsMutation,
} from '../slices/dataFilesApiSlice';
import { setMapping } from '../slices/dataSlice';
import { setNotificationCollapsed, setNotificationShown, setTaskMatchCollapsed } from '../slices/uiSlice';
import DescriptionTaskMatch from '../components/DescriptionTask';
import CynomiModal from '../modals/CynomiTaskModal';
import DynamicTaskTable from '../components/DynamicTaskTable';
import NotificationCenter from '../components/NotificationCenter';
import ErrorMessage from '../components/ErrorMessage';
import useFrameworkData from '../hooks/useFrameworkData';
import useCynomiData from '../hooks/useCynomiData';
import { useModal } from '../context/ModalContext';
import '../styles/global/colors.css';
import '../styles/pages/TaskPage.css';

const TaskPage = () => {
  const { framework, taskIndex } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { showModal } = useModal();

  const [scoreFilter, setScoreFilter] = useState(0.2);
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);
  const [isEditMode, setIsEditMode] = useState(false);
  const containerRef = useRef(null);

  // Decode the task index to handle special characters
  const decodedTaskIndex = decodeURIComponent(taskIndex);

  // Custom hooks for data fetching
  const { frameworkData, isLoading: isFrameworkLoading, error: frameworkError, refetchFrameworkData } = useFrameworkData(
    framework
  );
  const { cynomiCsvData, isLoading: isCynomiLoading, error: cynomiError } = useCynomiData();

  // Retrieve UI state from Redux
  const isTaskMatchCollapsed = useSelector((state) => state.ui.isTaskMatchCollapsed);
  const isNotificationShown = useSelector((state) => state.ui.isNotificationShown);
  const isNotificationCollapsed = useSelector((state) => state.ui.isNotificationCollapsed);

  // State to handle edited data
  const [editedData, setEditedData] = useState([]);

  // Update the data file's stats
  const [updateDataFileStats] = useUpdateDataFileStatsMutation();

  const [updateDataFile, { isLoading: loadingUpdate }] = useUpdateDataFileMutation();

  // Set the height of the description match container
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--description-match-height',
      isTaskMatchCollapsed ? '5%' : '25%'
    );
  }, [isTaskMatchCollapsed]);

  // Get control index from the framework data
  const controlIndex = frameworkData?.controlIndex;

  // Find the selected row data using memoization
  const selectedRowData = useMemo(() => {
    return frameworkData?.data?.find((row) => String(row[controlIndex]) === decodedTaskIndex);
  }, [frameworkData, controlIndex, decodedTaskIndex]);

  useEffect(() => {
    if (selectedRowData) {
      dispatch(setMapping(selectedRowData[frameworkData.userMappingIndex]));
      setEditedData([...selectedRowData]); // Initialize edited data with selected row data
    }
  }, [selectedRowData, dispatch, frameworkData]);

  const handleRowClick = useCallback(
    (row) => {
      showModal(<CynomiModal UID={row[1]} />);
    },
    [showModal]
  );

  const mapping = useSelector((state) => state.data.mapping);

  const addToMappingHandler = useCallback(
    (task) => {
      let newMapping = mapping ? mapping : '';
      let mappingArray = newMapping.split(/\s*,\s*/);

      if (mappingArray.length === 1 && mappingArray[0] === '') {
        mappingArray = [];
      }

      if (mappingArray.includes(task.UID)) {
        mappingArray = mappingArray.filter((e) => e !== task.UID);
      } else {
        mappingArray.push(task.UID);
      }

      newMapping = mappingArray.join(', ');

      setEditedData((prevData) => {
        const updatedData = [...prevData];
        updatedData[frameworkData.userMappingIndex] = newMapping;
        return updatedData;
      });

      dispatch(setMapping(newMapping));
    },
    [mapping, dispatch, frameworkData.userMappingIndex]
  );

  useEffect(() => {
    const updateContainerWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    updateContainerWidth();
    window.addEventListener('resize', updateContainerWidth);

    return () => {
      window.removeEventListener('resize', updateContainerWidth);
    };
  }, []);

  const isDataLoaded = frameworkData?.data?.length && cynomiCsvData?.length;

  const handleSaveTask = async () => {
    console.time('handleSaveTask');
    try {
      console.time('prepareBodyData');
      const bodyData = {
        name: framework,
        index: frameworkData.data.findIndex(
          (item) => item[controlIndex] === selectedRowData[controlIndex]
        ),
        newData: editedData,
      };
      console.timeEnd('prepareBodyData');

      console.time('updateDataFile');
      const result = await updateDataFile(bodyData).unwrap();
      console.timeEnd('updateDataFile');

      console.time('updateDataFileStats');
      // Remove await to let the stats update asynchronously
      updateDataFileStats({ name: framework });
      console.timeEnd('updateDataFileStats');

      if (result) {
        toast.success('Task updated successfully');
        setIsEditMode(false);
      }
    } catch (error) {
      toast.error(error?.data?.message || 'Error updating task');
    }
    console.timeEnd('handleSaveTask');
  };

  const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData([...selectedRowData]);
  };

  const toggleNotificationCenter = () => {
    dispatch(setNotificationCollapsed(!isNotificationCollapsed));
    if (!isNotificationShown) {
      dispatch(setNotificationShown(true));
    } else {
      setTimeout(() => {
        dispatch(setNotificationShown(false));
      }, 500);
    }
  };

  return (
    <div className="task-page">
      {isFrameworkLoading || isCynomiLoading || !isDataLoaded || loadingUpdate ? (
        <div className="loader-container">
          <HashLoader color="#8D8D8D" size={100} />
        </div>
      ) : frameworkError ? (
        <ErrorMessage message="Error fetching framework data." />
      ) : cynomiError ? (
        <ErrorMessage message="Error fetching Cynomi data." />
      ) : (
        <>
          <Navbar
            pageInfo={`Viewing ${framework} - ${taskIndex}`}
            allowControlFramework={false}
            allowNewFramework={false}
            homeLink={`${framework}?${searchParams.toString()}`}
            allowNewCynomiFile={false}
            addButton={[
              {
                text: 'Edit Task',
                icon: <TbEdit size={22} />,
                onClick: () => setIsEditMode(true),
                isVisible: !isEditMode,
              },
              {
                text: 'Save Task',
                icon: <TbSave size={22} />,
                onClick: handleSaveTask,
                isVisible: isEditMode,
              },
              {
                text: 'Cancel Edit',
                icon: <TbX size={22} />,
                onClick: handleCancelEdit,
                isVisible: isEditMode,
              },
            ]}
          />
          <div className="task-page-container">
            <div className="task-list">
              <TasksList />
            </div>
            <div className="task-content" ref={containerRef}>
              <div className="task-page-content">
                <DynamicTaskTable
                  containerWidth={containerWidth}
                  frameworkData={frameworkData}
                  selectedRowData={selectedRowData}
                  cynomiCsvData={cynomiCsvData}
                  mapping={mapping}
                  scoreFilter={scoreFilter}
                  setScoreFilter={setScoreFilter}
                  handleRowClick={handleRowClick}
                  addToMappingHandler={addToMappingHandler}
                  isEditMode={isEditMode}
                  setEditedData={setEditedData}
                  refetchFrameworkData={refetchFrameworkData}
                />
              </div>
            </div>
            {isNotificationShown && (
              <div className="notification-container">
                <NotificationCenter
                  isNotificationCollapsed={isNotificationCollapsed}
                  toggleCollapse={toggleNotificationCenter}
                />
              </div>
            )}
          </div>
          <DescriptionTaskMatch
            isCollapsed={isTaskMatchCollapsed}
            setIsCollapsed={(collapsed) => dispatch(setTaskMatchCollapsed(collapsed))}
          />
        </>
      )}
    </div>
  );
};

export default React.memo(TaskPage);

import React, { useState, useEffect } from 'react';
import '../styles/components/DataTable.css';
import { TbSortAscending, TbSortDescending, TbTableOff } from 'react-icons/tb';

const DataTable = ({ data, handleRowClick, filtersDisabled=false }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 50;

    useEffect(() => {
        if (data) {
            let headers = data[0];
            let tempData = [...data.slice(1)];
    
            if (sortDirection === 'desc') {
                tempData = tempData.reverse();
            }
    
            if (searchTerm) {
                tempData = tempData.filter(row =>
                    Object.values(row).some(value =>
                        value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
                    )
                );
            }
    
            tempData.unshift(headers);
            setFilteredData(tempData);
    
            setCurrentPage(1);
        }
    }, [data, searchTerm, sortDirection]);    

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to the first page when filtering changes
    };

    const handleSortChange = () => {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        setCurrentPage(1); // Reset to the first page when sorting changes
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const renderFilters = () => (
        <div className="table-filter-wrapper">
            <div className="table-filter">
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    name='search'
                />
                <button onClick={handleSortChange}>
                    Sort &nbsp; {sortDirection === 'asc' ? <TbSortAscending size={24}/> : <TbSortDescending size={24}/>}
                </button>
            </div>
        </div>
    );

    const totalPages = Math.ceil((filteredData.length - 1) / itemsPerPage);
    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage + 1,
        currentPage * itemsPerPage + 1
    );

    if (!data || data.length === 0) {
        return (
            <div className="no-data-wrapper">
                <TbTableOff size={48} />
                <p>No data available</p>
            </div>
        );
    } else {
        return (
            <>
                <div className="table-wrapper">
                    {!filtersDisabled && renderFilters()}
                    {filteredData.length <= 1 ? (
                        <div className="no-data-wrapper">
                            <TbTableOff size={48} />
                            <p>No data available with current filters</p>
                        </div>
                    ) : (
                        <>
                            <div className='table-scroll-wrapper'>
                                <table className="framework-table">
                                    <thead>
                                        <tr className='data-table-header-row'>
                                            {filteredData[0].map((item, index) => (
                                                <th key={index}>{item}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedData.map((row, rowIndex) => (
                                            <tr
                                                key={rowIndex}
                                                onClick={() => handleRowClick(row)}
                                                tabIndex={0} // Make the row focusable
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' || e.key === ' ') {
                                                        e.preventDefault();
                                                        handleRowClick(row);
                                                    }
                                                }}
                                            >
                                                {row.map((cell, cellIndex) => (
                                                    <td key={cellIndex}>
                                                        <div className="scrollable-content">{cell}</div>
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>
                {totalPages > 1 && (
                    <div className="pagination">
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                )}
            </>
        );
    }
};

export default DataTable;

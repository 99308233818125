// src/modals/EditNameModal.js
import React, { useState } from 'react';
import Modal from '../components/Modal';
import { useModal } from '../context/ModalContext';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setFramework } from '../slices/dataSlice';
import { toast } from 'react-toastify';
import { useUpdateDataFileNameMutation } from '../slices/dataFilesApiSlice';

const EditNameModal = ({ frameworkName }) => {
  const { hideModal } = useModal();
  const [newFrameworkName, setNewFrameworkName] = useState(frameworkName);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updateDataFileName] = useUpdateDataFileNameMutation();

  const handleConfirmedEdit = async () => {
    try {
      await updateDataFileName({ name: frameworkName, newName: newFrameworkName }).unwrap();
      dispatch(setFramework(newFrameworkName));
      toast.success(`${frameworkName} framework renamed to ${newFrameworkName}`);
      navigate(`/${newFrameworkName}`);
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
    hideModal();
  };

  return (
    <Modal title={`Edit ${frameworkName}'s name`}>
      <div className="input-container">
        <p className="input-label">Enter new name:</p>
        <input
          className="input-field"
          type="text"
          value={newFrameworkName}
          onChange={(e) => setNewFrameworkName(e.target.value)}
        />
      </div>
      <div className="button-container">
        <button className="form-action-button" onClick={handleConfirmedEdit}>
          Save
        </button>
        <button className="form-action-button" onClick={hideModal}>
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default EditNameModal;

import React, { useState, useEffect } from 'react';
import { FaCompressAlt } from 'react-icons/fa';
import { TbInboxOff, TbAlertCircle } from "react-icons/tb";
import '../styles/components/NotificationCenter.css';
import ProgressBar from './ProgressBar.js';
import { useGetNotificationsQuery } from '../slices/notificationApiSlice.js';
import { setIsNotificationCenterFirstRender } from '../slices/uiSlice.js';
import { BASE_URL, NOTIFICATIONS_URL } from '../constants.js';
import { useDispatch, useSelector } from 'react-redux';
import { HashLoader } from "react-spinners";

const NotificationCenter = ({ isNotificationCollapsed, toggleCollapse }) => {
  const dispatch = useDispatch();
  const initialRender = useSelector((state) => state.ui.isNotificationCenterFirstRender);
  const [animationClass, setAnimationClass] = useState(isNotificationCollapsed ? 'slide-out' : initialRender ? 'slide-in' : '');
  const [liveNotifications, setLiveNotifications] = useState([]);

  // Fetch notifications from the backend (initial load)
  const { data: notifications, isLoading, error } = useGetNotificationsQuery();

  // Log fetched notifications on initial load
  useEffect(() => {
    if (notifications) {
      setLiveNotifications(notifications);
    }
  }, [notifications]);

  // Control the animation class based on collapse state and initial render
  useEffect(() => {
    if (!initialRender && isNotificationCollapsed) {
      setAnimationClass('slide-out');
      dispatch(setIsNotificationCenterFirstRender(true));
    } else if (initialRender && !isNotificationCollapsed) {
      setAnimationClass('slide-in');
      dispatch(setIsNotificationCenterFirstRender(false));
    }
  }, [isNotificationCollapsed, initialRender, dispatch]);

  // Manually set up SSE subscription for real-time notifications
  useEffect(() => {
    if (!isNotificationCollapsed) {
      const eventSource = new EventSource(`${BASE_URL}${NOTIFICATIONS_URL}/subscribe`);

      eventSource.onmessage = (event) => {
        const notification = JSON.parse(event.data);
        setLiveNotifications((prevNotifications) => {
          const notificationExists = prevNotifications.some(n => n._id === notification._id);
          if (notificationExists) {
            return prevNotifications.map(n =>
              n._id === notification._id ? notification : n
            );
          } else {
            return [notification, ...prevNotifications];
          }
        });
      };

      eventSource.onerror = (err) => {
        console.error('SSE connection error:', err);
      };

      return () => {
        eventSource.close();
      };
    }
  }, [isNotificationCollapsed]);

  return (
    <div className={`notification-center ${animationClass}`}>
      <div className="notification-header">
        <h3>Notifications</h3>
        <button onClick={toggleCollapse} className="collapse-btn">
          <FaCompressAlt size={16} />
        </button>
      </div>
      <div className="notification-content">
        {isLoading ? (
          <div className="loading-notifications">
            <HashLoader size={60} className="loading-icon" color={"#8D8D8D"} />
            <p>Loading notifications...</p>
          </div>
        ) : error ? (
          <p>Error fetching notifications: {error.message}</p>
        ) : liveNotifications.length > 0 ? (
          <ul>
            {liveNotifications.map((notification) => (
              <li
                key={notification._id}
                className={`notification-item ${notification.type === 'error' ? 'error-notification' : ''}`}
              >
                <p className="notification-message">
                  {notification.type === 'error' && <TbAlertCircle size={18} className="error-icon" />} 
                  {notification.message}
                </p>
                {notification.type === 'upload' && (
                  <ProgressBar progress={notification.progress} />
                )}
                {notification.user && (
                  <p className="notification-user">{notification.user}</p>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <div className="no-notifications">
            <TbInboxOff size={80} color="var(--secondary-color)" />
            <p>No notifications available</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationCenter;

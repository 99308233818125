// fileUtils.js
import * as XLSX from 'xlsx';

export const processFile = (evt, file) => {
    const arrayBuffer = evt.target.result;
    const binaryString = new Uint8Array(arrayBuffer).reduce((acc, byte) => acc + String.fromCharCode(byte), '');
    const wb = XLSX.read(binaryString, { type: 'binary', cellDates: true, cellNF: true, cellText: false });
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    let data = [];
    const range = XLSX.utils.decode_range(ws['!ref']);
    let emptyColumns = new Array(range.e.c + 1).fill(true);

    for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
            let cell_address = { c: C, r: R };
            let cell_ref = XLSX.utils.encode_cell(cell_address);
            if (ws[cell_ref] && (ws[cell_ref].w || ws[cell_ref].v)) {
                emptyColumns[C] = false;
            }
        }
    }

    for (let R = range.s.r; R <= range.e.r; ++R) {
        let row = [];
        for (let C = range.s.c; C <= range.e.c; ++C) {
            if (!emptyColumns[C]) {
                let cell_address = { c: C, r: R };
                let cell_ref = XLSX.utils.encode_cell(cell_address);
                if (ws[cell_ref]) {
                    let value = ws[cell_ref].w || ws[cell_ref].v;
                    row.push(String(value));
                } else {
                    row.push('');
                }
            }
        }
        if (row.some(cell => cell !== '')) {
            data.push(row);
        }
    }

    return { name: file.name, data };
};

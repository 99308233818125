import React, { useEffect, useState, useRef } from 'react';
import '../styles/components/FrameworkList.css';
import { useDispatch, useSelector } from 'react-redux';
import { setFramework, setFrameworkData } from '../slices/dataSlice.js';
import { useGetDataFileByNameQuery } from '../slices/dataFilesApiSlice.js';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

const FrameworkTasksList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { framework, taskIndex } = useParams();
    const frameworkData = useSelector(state => state.data.frameworkData);
    const [tasks, setTasks] = useState([]);
    const activeTaskRef = useRef(null); // Reference to the active task
    const [searchParams] = useSearchParams(); // Get search params from URL
    const [hasValidStatistics, setHasValidStatistics] = useState(false);

    const { data: dataFile, isLoading, error } = useGetDataFileByNameQuery(framework);

    // Get the search parameters from the URL
    const includedInAIFilter = searchParams.get('includedInAI') || '';
    const freeTextSearchTerm = searchParams.get('search') || '';
    const sortDirection = searchParams.get('sort') || 'asc';

    useEffect(() => {
        if (frameworkData && frameworkData.data) {
          // Check if at least one statistic is not null
          if (frameworkData.statistics) {
            const hasValidStat = Object.values(frameworkData.statistics).some(
              (value) => value !== null
            );
            setHasValidStatistics(hasValidStat);
          }
    
          let tempData = [...frameworkData.data.slice(1)];
    
          // Handle Sorting
          if (sortDirection === 'desc') {
            tempData = tempData.reverse();
          }
    
          // Apply AI-Based Filtering only if statistics are valid
          if (hasValidStatistics && includedInAIFilter) {
            tempData = tempData.filter((row) =>
              row[row.length - 2]?.toString().toLowerCase().includes(includedInAIFilter.toLowerCase())
            );
          }
    
          // Handle Free-Text Search
          if (freeTextSearchTerm) {
            tempData = tempData.filter((row) =>
              Object.values(row).some((val) =>
                val.toString().toLowerCase().includes(freeTextSearchTerm.toLowerCase())
              )
            );
          }
    
          setTasks(tempData.map((row) => row[frameworkData.controlIndex]));
        } else {
          setFrameworkData(dataFile);
        }
      }, [
        frameworkData,
        includedInAIFilter,
        freeTextSearchTerm,
        sortDirection,
        hasValidStatistics,
        dataFile
      ]);

    useEffect(() => {
        // Scroll into view the active task after data is set
        if (activeTaskRef.current) {
            activeTaskRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [tasks]); // Run this effect when tasks are updated

    const handleTaskClick = (taskId) => {
        dispatch(setFramework(framework));
        navigate(`/${framework}/${encodeURIComponent(taskId)}?${searchParams.toString()}`);
    };

    return (
        <div className="framework-list ungroup">
            <h3>{framework}</h3>
            {isLoading ? (
                <ul>
                    <li>Loading...</li>
                </ul>
            ) : error ? (
                <div>{error?.data?.message || error.error}</div>
            ) : (
                <div className='framework-list-body'>
                    <ul>
                        {tasks.map((taskId) => (
                            <li key={taskId}>
                                <button
                                    className={`framework-button ${String(taskId) === decodeURIComponent(taskIndex) ? 'active' : ''}`}
                                    onClick={() => handleTaskClick(taskId)}
                                    ref={String(taskId) === decodeURIComponent(taskIndex) ? activeTaskRef : null} // Set reference to active task
                                >
                                    {taskId}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default FrameworkTasksList;

// src/components/ManageFrameworkOption.js
import React from 'react';
import { TbEdit, TbTrash, TbDownload, TbRefresh } from 'react-icons/tb';
import '../styles/components/ManageFrameworkOption.css';
import { utils, write } from 'xlsx';
import { useModal } from '../context/ModalContext';
import ConfirmDeleteModal from '../modals/ConfirmDeleteModal';
import EditNameModal from '../modals/EditNameModal';
import ReuploadModal from '../modals/ReuploadModal';
import { useGetDataFileByNameQuery } from '../slices/dataFilesApiSlice';

const saveArrayToExcel = (array, name) => {
  const ws = utils.aoa_to_sheet(array);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, 'Sheet1');
  const wbout = write(wb, { bookType: 'xlsx', type: 'binary' });
  const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = `${name}.xlsx`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

const ManageFrameworkOption = ({ text }) => {
  const { showModal } = useModal();
  const { data: excelData, isLoading } = useGetDataFileByNameQuery(text);

  const handleDeleteClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    showModal(<ConfirmDeleteModal frameworkName={text} />);
  };

  const handleDownloadClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (excelData && !isLoading) {
      const data = excelData.data;

      if (!Array.isArray(data) || data.length === 0) {
        console.error('Invalid data format for export.');
        return;
      }

      // Identify the header row
      const header = data[0];
      const aiMatchUidIndex = header.indexOf('AI Match UID');

      if (aiMatchUidIndex === -1) {
        console.error('AI Match UID column not found.');
        saveArrayToExcel(data, text);
        return;
      }

      // Transform the AI Match UID column
      const transformedData = data.map((row, rowIndex) => {
        // Always return the header row as is
        if (rowIndex === 0) return row;

        const aiMatchUidCell = `[${row[aiMatchUidIndex]}]`;

        // Check if the cell contains a string or an object
        let aiMatchArray;
        if (typeof aiMatchUidCell === 'string') {
          try {
            aiMatchArray = JSON.parse(aiMatchUidCell);
          } catch (error) {
            console.error(`Error parsing AI Match UID cell at row ${rowIndex + 1}:`, error);
            aiMatchArray = [];
          }
        } else if (Array.isArray(aiMatchUidCell)) {
          aiMatchArray = aiMatchUidCell;
        } else {
          aiMatchArray = [];
        }

        // Extract IDs
        const ids = aiMatchArray.map(item => item.ID).filter(Boolean).join(', ');

        // Replace the cell content with the list of IDs
        const newRow = [...row];
        newRow[aiMatchUidIndex] = ids;

        return newRow;
      });

      // Export the transformed data
      saveArrayToExcel(transformedData, text);
    }
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    showModal(<EditNameModal frameworkName={text} />);
  };

  const handleReuploadClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    showModal(<ReuploadModal frameworkName={text} excelData={excelData} />);
  };

  return (
    <div className="manage-framework-option">
      <div className="icon-buttons">
        <button className="edit-button edit-icon" onClick={handleEditClick}>
          <TbEdit size={20} />
        </button>
        <button className="download-button save-icon" onClick={handleDownloadClick}>
          <TbDownload size={20} />
        </button>
        <button className="delete-button delete-icon" onClick={handleDeleteClick}>
          <TbTrash size={20} />
        </button>
        <button className="rematch-button rematch-icon" onClick={handleReuploadClick}>
          <TbRefresh size={20} />
        </button>
      </div>
      <div className="text-wrapper">
        <span className="option-text">{text}</span>
      </div>
    </div>
  );
};

export default ManageFrameworkOption;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../constants.js'
import { setCredentials } from '../slices/authSlice'

const baseQuery = fetchBaseQuery({ baseUrl: BASE_URL })

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)

    if (result?.error?.status === 401) {
        // Dispatch an action to reset credentials when unauthorized
        api.dispatch(setCredentials(null))
    }

    return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Task', 'User', 'Data'],
    endpoints: (builder) => ({})
})

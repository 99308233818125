import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useLoginMutation } from '../slices/usersApiSlice.js';
import { setCredentials } from '../slices/authSlice.js';
import '../styles/global/colors.css'
import '../styles/pages/LoginPage.css';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const theme = localStorage.getItem('theme') || 'light';
  const dispatch = useDispatch()

  const [login, { error}] = useLoginMutation()

  useEffect(() => {
    // Initialize theme attribute on component mount
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      let res = await login({ username, password }).unwrap();
      dispatch(setCredentials(res));
      toast.info('Logged In Succesfully')
    } catch (err) {
      const errorMessage = error?.data?.message || err?.data?.message ||"An unexpected error occurred";
      toast.error(errorMessage);
    }
  };
  
  return (
    <div className="login-container">
      <div className="login-card">
        <h1 className="login-title text-center">Cynomi AI Content Helper</h1> {/* Title */}
        <form onSubmit={handleSubmit}>
          <div className="login-group">
            <label>Username</label>
            <input
              type="text"
              placeholder="Enter username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="login-input"
            />
          </div>
          <div className="login-group">
            <label>Password</label>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="login-input"
            />
          </div>
          <div className="text-center">
            <button type="submit" className="login-button">Login</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;

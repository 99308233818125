// src/modals/CynomiModal.js
import React from 'react';
import { useSelector } from 'react-redux';
import Modal from '../components/Modal';
import '../styles/modals/CynomiTaskModal.css';

const CynomiModal = ({ UID }) => {
  const cynomiCsvData = useSelector((state) => state.data.cynomiCsvData);

  const matchingData = cynomiCsvData.find((item) => item.ID === UID);

  return (
    <Modal title={`UID: ${UID}`}>
      {matchingData ? (
        <div className="modal-content-values">
          <div className="left">
            {Object.entries(matchingData)
              .filter((_, index) => index % 2 === 0)
              .map(([key, value], index) => {
                if (value && value.trim().length > 0) {
                  return (
                    <p key={index}>
                      <strong>{key}:</strong> {value}
                    </p>
                  );
                }
                return null;
              })}
          </div>
          <div className="right">
            {Object.entries(matchingData)
              .filter((_, index) => index % 2 !== 0)
              .map(([key, value], index) => {
                if (value && value.trim().length > 0) {
                  return (
                    <p key={index}>
                      <strong>{key}:</strong> {value}
                    </p>
                  );
                }
                return null;
              })}
          </div>
        </div>
      ) : (
        <p>No data found for UID: {UID}</p>
      )}
    </Modal>
  );
};

export default CynomiModal;

export const filterTasksById = (idsOrString, tasks) => {
    let ids = [];
    let scores = [];
    let reasons = [];

    if (typeof idsOrString === 'string' && idsOrString.includes("score")) {
        // Extract IDs, scores, and reasons if idsOrString is a string containing objects
        const idRegex = /"ID":"(.*?)"/g;
        const scoreRegex = /"score":([\d.]+)/g;
        const reasonRegex = /"reason":"(.*?)"/g;
        let idMatch;
        let scoreMatch;
        let reasonMatch;

        while ((idMatch = idRegex.exec(idsOrString)) !== null) {
            ids.push(idMatch[1]);
        }

        while ((scoreMatch = scoreRegex.exec(idsOrString)) !== null) {
            scores.push(parseFloat(scoreMatch[1]));
        }

        while ((reasonMatch = reasonRegex.exec(idsOrString)) !== null) {
            reasons.push(reasonMatch[1]);
        }   

    } else {
        // Assume idsOrString is already an array of IDs
        ids = idsOrString.includes(', ') ? idsOrString.split(', ') : idsOrString.split(',');
    }

    // First, filter the tasks based on the provided ids
    const filteredTasks = tasks.filter(task => ids.includes(task.ID));

    // Find any missing IDs that are not in the tasks
    const missingIds = ids.filter(id => !filteredTasks.some(task => task.ID === id) && id.length > 0);

    // Create a placeholder task for each missing ID
    const placeholderTasks = missingIds.map(id => ({
        UID: id,
        Task: null,
        Policy: null,
        Description: null,
    }));

    // Then, map each filtered task to extract, rename, and add the desired properties
    const transformedTasks = filteredTasks.map(task => {
        const index = ids.indexOf(task.ID);
        return {
            UID: task.ID,
            Task: task.Task,
            Policy: task['Policy'],
            Description: task['Task Description'],
            Score: index >= 0 ? scores[index] : null, // Add the matching score
            Reason: index >= 0 ? reasons[index] : null // Add the matching reason
        };
    });

    // If scores are available, sort the transformed tasks by score in descending order
    if (scores.length > 0) {
        transformedTasks.sort((a, b) => b.Score - a.Score);
    }

    // Combine the transformed tasks and the placeholder tasks
    return [...transformedTasks, ...placeholderTasks];
};

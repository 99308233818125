import { FRAMEWORK_GROUPS_URL } from '../constants.js';
import { apiSlice } from './apiSlice.js';

export const frameworkGroupApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Create a new framework group
    createFrameworkGroup: builder.mutation({
      query: (data) => ({
        url: `${FRAMEWORK_GROUPS_URL}`,
        method: 'POST',
        credentials: 'include',
        body: data,
      }),
      invalidatesTags: ['FrameworkGroup'],
    }),

    // Update an existing framework group's name or frameworks
    updateFrameworkGroup: builder.mutation({
      query: ({ id, data }) => ({
        url: `${FRAMEWORK_GROUPS_URL}/${id}`,
        method: 'PUT',
        credentials: 'include',
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'FrameworkGroup', id }],
    }),

    // Fetch all framework groups
    getFrameworkGroups: builder.query({
      query: () => ({
        url: `${FRAMEWORK_GROUPS_URL}`,
        method: 'GET',
        credentials: 'include',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: 'FrameworkGroup', id: _id })),
              { type: 'FrameworkGroup', id: 'LIST' },
            ]
          : [{ type: 'FrameworkGroup', id: 'LIST' }],
    }),

    // Fetch a single framework group by ID
    getFrameworkGroupById: builder.query({
      query: (id) => ({
        url: `${FRAMEWORK_GROUPS_URL}/${id}`,
        method: 'GET',
        credentials: 'include',
      }),
      providesTags: (result, error, id) => [{ type: 'FrameworkGroup', id }],
    }),

    // Delete a framework group
    deleteFrameworkGroup: builder.mutation({
      query: (id) => ({
        url: `${FRAMEWORK_GROUPS_URL}/${id}`,
        method: 'DELETE',
        credentials: 'include',
      }),
      invalidatesTags: [{ type: 'FrameworkGroup', id: 'LIST' }],
    }),
  }),
});

export const {
  useCreateFrameworkGroupMutation,
  useUpdateFrameworkGroupMutation,
  useGetFrameworkGroupsQuery,
  useGetFrameworkGroupByIdQuery,
  useDeleteFrameworkGroupMutation,
} = frameworkGroupApiSlice;

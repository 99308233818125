// components/TaskTabs.js
import React from 'react';
import { TbSearch, TbPlus, TbRobot, TbUser, TbUserEdit } from 'react-icons/tb';
import AllCynomiDataTable from './AllCynomiDataTable';

const TaskTabs = ({
  activeTab,
  setActiveTab,
  dataKeys,
  specialRows,
  handleRowClick,
  addToMappingHandler,
  isEditMode,
  addTaskContent
}) => {
  return (
    <div className="tabs-container">
      <div className="tabs">
        <button
          className={`tab ${activeTab === 'Search Task' ? 'active' : ''}`}
          onClick={() => setActiveTab('Search Task')}
        >
          <TbSearch size={20} /> &nbsp;&nbsp; Search Task
        </button>
        <button
          className={`tab ${activeTab === 'Add Task' ? 'active' : ''}`}
          onClick={() => setActiveTab('Add Task')}
        >
          <TbPlus size={20} /> &nbsp;&nbsp; Add Task
        </button>
        {dataKeys.includes("Predefined UID") && (
          <button
            className={`tab ${activeTab === 'Predefined UID' ? 'active' : ''}`}
            onClick={() => setActiveTab('Predefined UID')}
          >
            <TbUser size={20} /> &nbsp;&nbsp; Predefined UID
          </button>
        )}
        <button
          className={`tab ${activeTab === 'AI Match UID' ? 'active' : ''}`}
          onClick={() => setActiveTab('AI Match UID')}
        >
          <TbRobot size={20} /> &nbsp;&nbsp; AI Match UID
        </button>
        <button
          className={`tab ${activeTab === 'User Mapping' ? 'active' : ''}`}
          onClick={() => setActiveTab('User Mapping')}
        >
          <TbUserEdit size={20} /> &nbsp;&nbsp; User Mapping
        </button>
      </div>
      {Object.keys(specialRows).map(key => (
        <div key={key} className={`tab-content ${activeTab === key ? 'active' : ''}`}>
          {specialRows[key]}
        </div>
      ))}
      {activeTab === 'Search Task' && (
        <div className={`tab-content ${activeTab === 'Search Task' ? 'active' : ''}`}>
          <AllCynomiDataTable handleRowClick={handleRowClick} addToMappingHandler={addToMappingHandler} disabledCheckBox={!isEditMode} />
        </div>
      )}
      {activeTab === 'Add Task' && (
        <div className={`tab-content ${activeTab === 'Add Task' ? 'active' : ''}`}>
          {addTaskContent}
        </div>
      )}
    </div>
  );
};

export default TaskTabs;

import React, { useState, useRef, useEffect } from 'react';
import { TbChevronDown, TbChevronUp } from 'react-icons/tb';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import '../styles/components/Dropdown.css';

const Dropdown = ({ title, options, multiple, value, onChange, icon, buttonClassName, up, CustomOption, checkboxes = true, notInline = false, groups = null }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isGroupsExpanded, setIsGroupsExpanded] = useState(false); // Track if the groups section is expanded or collapsed
  const dropdownRef = useRef(null);

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const toggleOption = (option) => {
    if (multiple) {
      let newValue;
      if (value.includes(option)) {
        newValue = value.filter(item => item !== option);
      } else {
        newValue = [...value, option];
      }
      onChange(newValue);
    } else {
      onChange([option]);
      setIsOpen(false);
    }
  };

  const handleSelectAll = () => {
    onChange(options);
  };

  const handleClearAll = () => {
    onChange([]);
  };

  const handleSelectGroup = (groupItems) => {
    onChange(groupItems);
  };

  const toggleGroupsExpansion = () => {
    setIsGroupsExpanded(!isGroupsExpanded); // Toggle the state of the groups section
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen === false && value.length === 0 && multiple) {
      onChange(options);
    }
  }, [isOpen, multiple, onChange, options, value]);

  return (
    <div ref={dropdownRef} className={`dropdown-container ${notInline && 'dropdown-not-inline'}`}>
      <button className={`dropdown-button ${buttonClassName}`} onClick={handleButtonClick}>
        {icon && <span className="dropdown-icon">{icon}</span>}
        {title && title} {checkboxes ? multiple ? `- ${value.length} items chosen` : `${value}` : ''}
        <span className='dropdown-icon-toggle'>{isOpen ? up ? <TbChevronDown /> : <TbChevronUp /> : up ? <TbChevronUp /> : <TbChevronDown />}</span>
      </button>
      <ul className={`dropdown-list ${isOpen ? 'open' : 'close'} ${up ? 'up' : ''}`}>
        {multiple && checkboxes && (
          <>
            <li className={`dropdown-item select-clear-buttons ${groups && 'multiple'}`}>
              <button className="dropdown-action-button" onClick={handleSelectAll} type="button">
                Select All
              </button>
              {groups && (
                <>
                  <div className="dropdown-divider-vertical"></div>
                  <button className="dropdown-action-button" onClick={toggleGroupsExpansion} type="button">
                    Groups
                  </button>
                  <div className="dropdown-divider-vertical"></div>
                </>
              )}
              <button className="dropdown-action-button" onClick={handleClearAll} type="button">
                Clear
              </button>
            </li>
            <li className="dropdown-divider"></li>

            {isGroupsExpanded && (
              <>
                {groups.map((group, index) => (
                  <li className="dropdown-item select-clear-buttons" key={index}>
                    <button
                      className="dropdown-action-button"
                      onClick={() => handleSelectGroup(group.items)}
                      type="button"
                    >
                      {group.name}
                    </button>
                  </li>
                ))}
                <li className="dropdown-divider"></li>
              </>
            )}
          </>
        )}
        {options.map((option, index) => (
          <li
            key={index}
            className={`dropdown-item ${index < options.length - 1 ? 'with-border' : ''}`}
            onClick={() => (checkboxes ? toggleOption(option) : null)}
          >
            {isOpen && (
              <div className="dropdown-item-content">
                {checkboxes && multiple && (
                  <span className="checkbox-icon">
                    {value.includes(option) ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
                  </span>
                )}
                &nbsp;
                {CustomOption ? <CustomOption text={option} /> : option}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;

import { FEATURES_URL } from '../constants.js';
import { apiSlice } from './apiSlice.js';

export const featuresApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTaskFromDesc: builder.mutation({
        query: (data) => ({
            url: `${FEATURES_URL}/getTaskFromDesc`,
            method: 'POST',
            body: data,
            credentials: 'include'
        }),
        keepUnusedDataFor: 5
        }),
        getAllPolicies: builder.query({
        query: () => ({
            url: `compliance/get-policies`,
            method: 'GET',
            credentials: 'include'
        }),
        keepUnusedDataFor: 5
        }),
        updateCynomiTasksFile: builder.mutation({
        query: ({ file, mapping }) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('mapping', JSON.stringify(mapping));
            return {
            url: `/compliance/update-pinecone`,
            method: 'POST',
            body: formData,
            credentials: 'include'
            };
        },
        keepUnusedDataFor: 5
        })
    })
});

export const { useGetTaskFromDescMutation, useGetAllPoliciesQuery, useUpdateCynomiTasksFileMutation } = featuresApiSlice;
